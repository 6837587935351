import * as React from "react";
import { UserAuth, UserPermissions, caseReviewAuthApi } from "@/api/auth";
import useLocalStorage from "use-local-storage";

export interface AuthContextType {
  set: {
    setUser?: (user: UserAuth) => void;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  };
  values: {
    loading?: boolean;
    user?: UserAuth;
    isLoggedin?: boolean;
    userPermission?: UserPermissions;
    isAdmin: boolean;
    isEditor: boolean;
    isReviewer: boolean;
    backendVersion?: string;
    timer?: ActiveTimer;
  };
  login?: (
    username: string,
    password: string,
    otp_code?: string,
  ) => Promise<void>;
  startTimer?: (
    description: string,
    caseId?: string,
    caseName?: string,
  ) => void;
  stopTimer?: () => void;
  logout?: () => void;
  refreshToken?: () => void;
  fetchUserPermissions?: (userData: UserAuth) => Promise<void>;
}

export interface ActiveTimer {
  description: string;
  caseId?: string;
  caseName?: string;
  userId: number;
  startTime: number;
  endTime: number;
}

export const AuthContext = React.createContext<AuthContextType>({
  set: {},
  values: {
    isAdmin: false,
    isEditor: false,
    isReviewer: false,
  },
});

const AuthContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const [user, setUser] = useLocalStorage<UserAuth | undefined>(
    "user",
    undefined,
  );
  const [userPermission, setUserPermission] = React.useState<UserPermissions>();
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [isEditor, setIsEditor] = React.useState<boolean>(false);
  const [isReviewer, setIsReviewer] = React.useState<boolean>(false);
  const [backendVersion, setBackendVersion] = React.useState<string>();
  const [timer, setTimer] = React.useState<ActiveTimer>();

  // Getting Timer from localStorage to App Context
  React.useEffect(() => {
    if (user?.user.id) {
      const savedTimer = localStorage.getItem(`timer-${user.user.id}`);
      if (savedTimer) {
        const timerData = JSON.parse(savedTimer) as ActiveTimer;
        if (timerData && timerData.userId === user?.user.id) {
          setTimer(timerData);
        }
      }
    }
  }, [user?.user.id]);

  // Setting Timer state to localStorage
  React.useEffect(() => {
    if (timer && user?.user.id) {
      localStorage.setItem(`timer-${user.user.id}`, JSON.stringify(timer));
    }
  }, [timer, user?.user.id]);

  const startTimer = (
    description: string,
    caseId?: string,
    caseName?: string,
  ) => {
    if (user?.user.id) {
      setTimer({
        userId: user?.user.id,
        caseId,
        caseName,
        description,
        startTime: new Date().getTime(),
      } as ActiveTimer);
    }
  };

  const stopTimer = () => {
    setTimer(undefined);
    if (user?.user.id) {
      localStorage.removeItem(`timer-${user.user.id}`);
    }
  };

  const logoutCleanup = () => {
    setUser(undefined);
    window.location.replace("/login");
  };

  const logout = () => {
    if (user?.refresh) {
      caseReviewAuthApi
        .logout({ refresh_token: user.refresh })
        .catch(console.error)
        .finally(logoutCleanup);
    } else {
      logoutCleanup();
    }
  };

  const refreshToken = () => {
    if (user?.refresh) {
      caseReviewAuthApi
        .refreshToken({ refresh_token: user.refresh })
        .then(({ data }) => {
          setUser({
            ...user,
            access: data.access,
          });
        })
        .catch((e) => {
          console.error(e);
          logout();
        });
    } else {
      logout();
    }
  };

  const isLoggedin = !!user;

  const login = (username: string, password: string, otp_code?: string) => {
    return caseReviewAuthApi
      .login({ username: username, password: password, otp_code })
      .then((response) => {
        setUser(response.data);
        fetchUserPermissions(response.data);
      });
  };

  const fetchUserPermissions = (userData: UserAuth) => {
    return caseReviewAuthApi
      .currentUser({
        token: userData.access,
      })
      .then((response) => {
        setUserPermission(response.data.permissions);
        setIsAdmin(response.data.has_admin_permissions || false);
        setIsEditor(response.data.has_edit_permissions || false);
        setIsReviewer(response.data.has_review_permissions || false);
        setBackendVersion(response.data.backend_git_commit);
      });
  };

  React.useEffect(() => {
    if (user && !userPermission) {
      fetchUserPermissions(user);
    }
  }, [user, userPermission]);

  const contextValues = {
    set: {
      setUser,
      setLoading,
    },
    values: {
      user,
      userPermission,
      loading,
      isLoggedin,
      isAdmin,
      isEditor,
      isReviewer,
      backendVersion,
      timer,
    },
    fetchUserPermissions,
    login,
    logout,
    refreshToken,
    startTimer,
    stopTimer,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
