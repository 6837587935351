import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { cn, renderDateString, zodKeys } from "@/lib/utils";
import { Deadline, deadlinesApi } from "@/api/deadlines";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { Case, casesApi } from "@/api/cases";
import { useListApi } from "@/hooks/useListApi";
import { DynamicSelectorV2 } from "../DynamicSelectorV2";
import { useTranslation } from "react-i18next";

export function AddUpdateDeadline({
  open,
  setOpen,
  deadline,
  trigger,
  refresh,
  defaultCase,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deadline?: Deadline;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
  defaultCase?: Case;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { loading, user } = contextValues;
  const { setLoading } = set;

  const {
    loading: casesLoading,
    search: caseSearch,
    loadNext: loadNextCase,
    list: casesList,
    apiResult: casesResult,
    pagination,
  } = useListApi<Case>({
    baseApiObject: casesApi,
    enable: open,
    additionalListIds: [deadline?.case?.toString()],
  });

  const FormSchema = z.object({
    name: z.string(),
    date: z.date().nullable().optional(),
    notes: z.string(),
    private: z.boolean().default(false),
    case: z.string().min(1, {
      message: t("common.statictext.select_case"),
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      date: null,
      notes: "",
      private: false,
      case: "",
    },
  });

  const errorHandler = useApiErrorHandler("AddUpdateDeadline", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      if (deadline && deadline.id) {
        await deadlinesApi.update({
          id: deadline.id.toString() + "/",
          data: {
            name: data.name,
            date: data.date?.toLocaleDateString("sv"),
            notes: data.notes,
            private: data.private,
            case: parseInt(data.case),
            updated_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.updated_successfully", {
            resource: t("deadlines.title.deadline"),
          }),
        });
      } else {
        await deadlinesApi.create({
          url: "/",
          data: {
            name: data.name,
            date: data.date?.toLocaleDateString("sv"),
            notes: data.notes,
            private: data.private,
            case: parseInt(data.case),
            created_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.added_successfully", {
            resource: t("deadlines.title.deadline"),
          }),
        });
        form.reset();
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (deadline) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...deadline,
            case: deadline.case.toString(),
            date: deadline.date
              ? new Date(renderDateString(deadline.date, true))
              : deadline.date,
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadline]);

  useEffect(() => {
    if (defaultCase?.id && open) {
      form.setValue("case", defaultCase.id.toString(), { shouldTouch: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCase, open]);

  const buttonAction = deadline?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("deadlines.title.deadline")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("deadlines.placeholder.name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="private"
              render={({ field }) => (
                <div>
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("common.label.private")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>* {t("common.label.date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("deadlines.statictext.date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("date", null);
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.notes")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("deadlines.placeholder.notes")}
                      className="resize-none"
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {!defaultCase ? (
              <FormField
                control={form.control}
                name="case"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("common.label.case")}{" "}
                      {casesLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <DynamicSelectorV2
                      onValueChange={field.onChange}
                      value={field.value || undefined}
                      options={casesList.map((item) => ({
                        label: item.name,
                        value: item.id.toString(),
                      }))}
                      className="selector"
                      onLoadNext={loadNextCase}
                      onSearch={(searchValue) => {
                        caseSearch.set(searchValue);
                      }}
                      placeholder={t("deadlines.placeholder.case")}
                      totalCount={casesResult?.count || 0}
                      pageSize={pagination.pageSize}
                      currentPage={pagination.page}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : (
              defaultCase.name
            )}

            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
