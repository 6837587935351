import React, { useCallback, useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { MultiSelect } from "@/components/ui/select";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import {
  ResearchDocument,
  ResearchDocumentFolder,
  researchDocumentFolderApi,
  researchDocumentLegacyApi,
  ResearchFolder,
  researchFolderApi,
} from "@/api/research";
import { useListApi } from "@/hooks/useListApi";
import { useTranslation } from "react-i18next";

const FormSchema = z.object({
  folders: z.string().array(),
});

export function MoveResearchDocument({
  open,
  setOpen,
  document,
  trigger,
  refresh,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  document: ResearchDocument;
  trigger?: JSX.Element;
  refresh: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const { apiResult: researchDocumentFoldersList } =
    useListApi<ResearchDocumentFolder>({
      baseApiObject: researchDocumentFolderApi,
      defaultPageSize: 1000,
      defaultParams: {
        document: document.id,
      },
      enable: open,
    });

  const { apiResult: researchFoldersList, loading: loadingResearchFolders } =
    useListApi<ResearchFolder>({
      baseApiObject: researchFolderApi,
      defaultPageSize: 1000,
      enable: open,
    });

  const errorHandler = useApiErrorHandler("MoveResearchDocument", {});

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      folders: [],
    },
  });

  const onSubmit = useCallback(
    async (data: z.infer<typeof FormSchema>) => {
      try {
        setLoading?.(true);
        await researchDocumentLegacyApi.moveDocuments({
          token: user?.access,
          documentId: document.id,
          folderIds: data.folders,
        });
        toast({
          title: t("research.toast.moved_successfully"),
        });
        await refresh();
        setOpen(false);
        form.reset();
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading?.(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document, user?.access],
  );

  useEffect(() => {
    if (document) {
      form.setValue(
        "folders",
        researchDocumentFoldersList?.results
          .filter((item) => item.document.id === document.id)
          .map((item) => item.folder.toString()) || [],
        {
          shouldTouch: true,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, researchDocumentFoldersList?.results]);

  const buttonAction = t("common.button.move");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {buttonAction} {t("research.title.research_document")}
              </DialogTitle>
            </DialogHeader>

            <FormField
              control={form.control}
              name="folders"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("research.label.select_folders")}{" "}
                    {loadingResearchFolders
                      ? t("common.button.loading_with_parens")
                      : ""}
                  </FormLabel>
                  <MultiSelect
                    selected={field.value || []}
                    options={
                      researchFoldersList?.results
                        .filter((item) => item.public !== document.private)
                        .map((item) => ({
                          label: item.name,
                          value: item.id.toString(),
                        })) || []
                    }
                    {...field}
                    className="sm:w-[510px]"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
