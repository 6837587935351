import { BaseApi } from "@/api/index";
import { AxiosResponse } from "axios";
import { UserDetail } from "./users";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface UserPermissions {
  "communications.add_communication": boolean;
  "review.view_attorneycontact": boolean;
  "review.change_casecriminal": boolean;
  "pleadings.add_pleading": boolean;
  "django_rest_passwordreset.change_resetpasswordtoken": boolean;
  "review.add_customtagthrough": boolean;
  "case_users.add_userprofile": boolean;
  "notes.change_note": boolean;
  "research.view_folder": boolean;
  "review.access_casesources": boolean;
  "discovery.view_discovery": boolean;
  "review.add_imagetag": boolean;
  "sessions.add_session": boolean;
  "auth.view_user": boolean;
  "review.view_casecivil": boolean;
  "review.change_event": boolean;
  "taggit.add_taggeditem": boolean;
  "review.add_deadline": boolean;
  "review.view_source": boolean;
  "review.add_case": boolean;
  "pleadings.change_pleading": boolean;
  "research.change_documentfolder": boolean;
  "research.view_author": boolean;
  "django_celery_results.change_taskresult": boolean;
  "django_celery_results.add_groupresult": boolean;
  "registration.change_registrationprofile": boolean;
  "django_celery_results.delete_groupresult": boolean;
  "review.access_case": boolean;
  "review.delete_source": boolean;
  "auth.add_permission": boolean;
  "otp_totp.view_totpdevice": boolean;
  "review.delete_attorneycontact": boolean;
  "contenttypes.view_contenttype": boolean;
  "admin.change_logentry": boolean;
  "admin.view_logentry": boolean;
  "django_rest_passwordreset.delete_resetpasswordtoken": boolean;
  "timelines.view_timeline": boolean;
  "review.view_imagetag": boolean;
  "review.add_casecriminal": boolean;
  "otp_totp.change_totpdevice": boolean;
  "otp_totp.delete_totpdevice": boolean;
  "guardian.view_userobjectpermission": boolean;
  "deadlines.add_deadline": boolean;
  "case_users.delete_userprofile": boolean;
  "case_users.change_userprofile": boolean;
  "contenttypes.delete_contenttype": boolean;
  "guardian.add_groupobjectpermission": boolean;
  "guardian.change_groupobjectpermission": boolean;
  "guardian.delete_userobjectpermission": boolean;
  "admin.add_logentry": boolean;
  "auth.change_group": boolean;
  "guardian.change_userobjectpermission": boolean;
  "guardian.add_userobjectpermission": boolean;
  "research.add_documentfolder": boolean;
  "timelines.add_timeline": boolean;
  "events.view_event": boolean;
  "notes.add_note": boolean;
  "review.delete_case": boolean;
  "deadlines.change_deadline": boolean;
  "review.view_casecriminal": boolean;
  "research.change_author": boolean;
  "review.add_attorneycontact": boolean;
  "review.change_deadline": boolean;
  "review.delete_casecivil": boolean;
  "events.change_event": boolean;
  "guardian.view_groupobjectpermission": boolean;
  "taggit.delete_tag": boolean;
  "contenttypes.change_contenttype": boolean;
  "auth.add_group": boolean;
  "timelines.delete_timeline": boolean;
  "auth.delete_permission": boolean;
  "django_celery_results.view_groupresult": boolean;
  "timelines.change_timeline": boolean;
  "research.add_research": boolean;
  "django_celery_results.change_chordcounter": boolean;
  "django_celery_results.add_chordcounter": boolean;
  "auth.view_permission": boolean;
  "discovery.add_discovery": boolean;
  "review.delete_deadline": boolean;
  "review.change_casetag": boolean;
  "review.change_source": boolean;
  "django_celery_results.delete_chordcounter": boolean;
  "review.change_imagetag": boolean;
  "taggit.add_tag": boolean;
  "django_celery_results.add_taskresult": boolean;
  "communications.change_communication": boolean;
  "taggit.change_tag": boolean;
  "sessions.view_session": boolean;
  "django_celery_results.view_taskresult": boolean;
  "events.add_event": boolean;
  "review.delete_customtagthrough": boolean;
  "review.change_case": boolean;
  "pleadings.delete_pleading": boolean;
  "research.delete_author": boolean;
  "research.change_folder": boolean;
  "review.change_customtagthrough": boolean;
  "events.delete_event": boolean;
  "auth.add_user": boolean;
  "research.view_research": boolean;
  "discovery.delete_discovery": boolean;
  "review.view_customtagthrough": boolean;
  "taggit.delete_taggeditem": boolean;
  "review.view_casetag": boolean;
  "review.add_casetag": boolean;
  "review.delete_casetag": boolean;
  "taggit.view_tag": boolean;
  "auth.view_group": boolean;
  "communications.delete_communication": boolean;
  "otp_totp.add_totpdevice": boolean;
  "auth.delete_group": boolean;
  "deadlines.delete_deadline": boolean;
  "pleadings.view_pleading": boolean;
  "sessions.delete_session": boolean;
  "guardian.delete_groupobjectpermission": boolean;
  "research.delete_folder": boolean;
  "research.delete_documentfolder": boolean;
  "review.delete_event": boolean;
  "notes.view_note": boolean;
  "django_rest_passwordreset.view_resetpasswordtoken": boolean;
  "deadlines.view_deadline": boolean;
  "research.add_author": boolean;
  "auth.delete_user": boolean;
  "contenttypes.add_contenttype": boolean;
  "registration.delete_registrationprofile": boolean;
  "django_celery_results.view_chordcounter": boolean;
  "sessions.change_session": boolean;
  "registration.add_registrationprofile": boolean;
  "auth.change_permission": boolean;
  "discovery.change_discovery": boolean;
  "review.view_case": boolean;
  "review.add_source": boolean;
  "research.add_folder": boolean;
  "django_celery_results.change_groupresult": boolean;
  "taggit.change_taggeditem": boolean;
  "research.view_documentfolder": boolean;
  "auth.change_user": boolean;
  "review.delete_casecriminal": boolean;
  "notes.delete_note": boolean;
  "research.delete_research": boolean;
  "review.access_source": boolean;
  "review.delete_imagetag": boolean;
  "review.add_casecivil": boolean;
  "review.change_attorneycontact": boolean;
  "research.change_research": boolean;
  "case_users.view_userprofile": boolean;
  "admin.delete_logentry": boolean;
  "django_rest_passwordreset.add_resetpasswordtoken": boolean;
  "research.can_view_associated_cases": boolean;
  "review.change_casecivil": boolean;
  "taggit.view_taggeditem": boolean;
  "communications.view_communication": boolean;
  "django_celery_results.delete_taskresult": boolean;
  "review.add_event": boolean;
}

export interface CurrentUser {
  id: number;
  is_superuser: boolean;
  username: string;
  email: string;
  is_active: boolean;
  groups: number[];
  user_permissions: number[];
  permissions?: UserPermissions;
  has_admin_permissions?: boolean;
  has_edit_permissions?: boolean;
  has_review_permissions?: boolean;
  backend_git_commit?: string;
}

export interface UserAuth {
  user: CurrentUser;
  access: string;
  refresh: string;
}

class AuthApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl });
  }

  sendResetPasswordLink({ email }: { email: string }) {
    return this.request<unknown, AxiosResponse<{ detail: string }>>({
      method: "POST",
      url: "/dj-rest-auth/password/reset/",
      data: {
        email,
      },
    });
  }

  resetPasswordConfirm({
    uid,
    token,
    new_password1,
    new_password2,
  }: {
    uid: string;
    token: string;
    new_password1: string;
    new_password2: string;
  }) {
    return this.request<unknown, AxiosResponse<{ detail: string }>>({
      method: "POST",
      url: "/dj-rest-auth/password/reset/confirm/",
      data: {
        uid,
        token,
        new_password1,
        new_password2,
      },
    });
  }

  login({
    username,
    password,
    otp_code,
  }: {
    username: string;
    password: string;
    otp_code?: string;
  }) {
    return this.request<unknown, AxiosResponse<UserAuth>>({
      method: "POST",
      url: "/api/token/",
      data: {
        username,
        password,
        otp_code,
      },
    });
  }

  logout({ refresh_token }: { refresh_token: string }) {
    return this.request<unknown, AxiosResponse<UserAuth>>({
      method: "POST",
      url: "/api/token/logout/",
      data: {
        refresh: refresh_token,
      },
    });
  }

  refreshToken({ refresh_token }: { refresh_token: string }) {
    return this.request<unknown, AxiosResponse<{ access: string }>>({
      method: "POST",
      url: "/api/token/refresh/",
      data: {
        refresh: refresh_token,
      },
    });
  }

  currentUser({ token }: { token: string }) {
    return this.request<unknown, AxiosResponse<CurrentUser>>({
      method: "GET",
      url: "/case_users/current_user/",
      token,
    });
  }

  check2FAEnabled({ id, token }: { id?: string; token?: string }) {
    return this.request<
      unknown,
      AxiosResponse<{ enabled?: boolean; confirmed?: boolean }>
    >({
      method: "GET",
      url: "/case_users/check-2fa/",
      token,
      params: {
        user_id: id,
      },
    });
  }

  disableMy2FA({ token }: { token?: string }) {
    return this.request<unknown, AxiosResponse<{ success?: boolean }>>({
      method: "GET",
      url: "/case_users/disable-2fa/",
      token,
    });
  }

  forceDisable2FA({ id, token }: { id: string; token?: string }) {
    return this.request<unknown, AxiosResponse<{ success?: boolean }>>({
      method: "GET",
      url: "/case_users/force-disable-2fa/",
      token,
      params: {
        user_id: id,
      },
    });
  }

  fetch2FaQa({ token }: { token?: string }) {
    return this.request<unknown, AxiosResponse<{ qr_code_url?: string }>>({
      method: "GET",
      url: "/case_users/setup-2fa/",
      token,
    });
  }

  verify2FaCode({ token, code }: { token?: string; code?: string }) {
    return this.request<unknown, AxiosResponse<{ success?: boolean }>>({
      method: "POST",
      url: "/case_users/setup-2fa/",
      token,
      data: {
        verification_code: code,
      },
    });
  }

  fetchEULA({ token, user_id }: { token?: string; user_id?: number }) {
    return this.request<unknown, AxiosResponse<{ accepted_eula?: boolean }>>({
      method: "GET",
      url: "/case_users/eula/",
      token,
      params: {
        user_id,
      },
    });
  }

  acceptEULA({ token }: { token?: string; code?: string }) {
    return this.request<unknown, AxiosResponse<{ accepted_eula?: boolean }>>({
      method: "POST",
      url: "/case_users/eula/",
      token,
    });
  }

  fetchUserDetails({ userId, token }: { userId: number; token?: string }) {
    return this.request<unknown, AxiosResponse<UserDetail>>({
      method: "GET",
      url: `/case_users/api/user/${userId}/`,
      token,
    });
  }
}

export const caseReviewAuthApi = new AuthApi<UserAuth | CurrentUser>();
