import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { cn, renderDateString, zodKeys } from "@/lib/utils";
import { CaseCivil, civilCasesApi } from "@/api/cases";
import { Checkbox } from "@/components/ui/checkbox";
import { Textarea } from "@/components/ui/textarea";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { useListApi } from "@/hooks/useListApi";
import { DynamicSelectorV2 } from "../DynamicSelectorV2";
import { AttorneyContact, attorneyContactApi } from "@/api/attorneyContacts";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import JsonDisplay from "../JsonDisplay";
import { User, userApi } from "@/api/users";
import { useTranslation } from "react-i18next";

export function AddUpdateCivilCase({
  open,
  setOpen,
  reviewCase,
  trigger,
  refresh,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reviewCase?: CaseCivil;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const FormSchema = z.object({
    name: z.string().min(3, {
      message: t("common.statictext.name_too_short"),
    }),
    court: z.string().nullable().optional(),
    defendant: z.string().nullable().optional(),
    plaintiff: z.string().nullable().optional(),
    exact_trial_date: z.date().nullable().optional(),
    inexact_trial_date: z.string().nullable().optional(),
    attorney_contact: z.string().nullable().optional(),
    internal_contact: z.string().nullable().optional(),
    overview: z.string().nullable().optional(),
    folder: z.string().nullable().optional(),
    archived: z.boolean().default(false).nullable().optional(),

    // Civil Case (isCivil)
    lead_counsel: z.string().nullable().optional(),
    co_counsel: z.string().nullable().optional(),
    opposing_counsel: z.string().nullable().optional(),
    lawsuit_type: z.string().nullable().optional(),
  });

  const { loading, user } = contextValues;
  const { setLoading } = set;
  const {
    loading: attorneyContactLoading,
    search,
    loadNext,
    list: attorneyContacts,
    apiResult: attorneyContactsResult,
    pagination: contactPagination,
  } = useListApi<AttorneyContact>({
    baseApiObject: attorneyContactApi,
    enable: open,
    additionalListIds: [
      reviewCase?.attorney_contact?.toString(),
      reviewCase?.subclass_case_object?.lead_counsel?.toString(),
      reviewCase?.subclass_case_object?.co_counsel?.toString(),
      reviewCase?.subclass_case_object?.opposing_counsel?.toString(),
    ],
  });

  const {
    loading: usersLoading,
    search: searchUsers,
    loadNext: loadNextUsers,
    list: usersList,
    apiResult: usersResult,
    pagination,
  } = useListApi<User>({
    baseApiObject: userApi,
    enable: open,
    additionalListIds: [reviewCase?.internal_contact?.toString()],
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      court: "",
      defendant: "",
      plaintiff: "",
      exact_trial_date: null,
      inexact_trial_date: "",
      attorney_contact: "",
      internal_contact: "",
      overview: "",
      folder: "",
      archived: false,

      // Civil Case (isCivil)
      lead_counsel: "",
      co_counsel: "",
      opposing_counsel: "",
      lawsuit_type: "",
    },
  });

  const errorHandler = useApiErrorHandler("AddUpdateCivilCase", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      if (reviewCase && reviewCase.id) {
        await civilCasesApi.update({
          id: reviewCase.id.toString() + "/",
          data: {
            name: data.name,
            court: data.court,
            defendant: data.defendant,
            plaintiff: data.plaintiff,
            exact_trial_date: data.exact_trial_date?.toLocaleDateString("sv"),
            inexact_trial_date: data.inexact_trial_date,
            attorney_contact: data.attorney_contact
              ? parseInt(data.attorney_contact)
              : undefined,
            internal_contact: data.internal_contact
              ? parseInt(data.internal_contact)
              : undefined,
            lead_counsel: data.lead_counsel
              ? parseInt(data.lead_counsel)
              : undefined,
            co_counsel: data.co_counsel ? parseInt(data.co_counsel) : undefined,
            opposing_counsel: data.opposing_counsel
              ? parseInt(data.opposing_counsel)
              : undefined,
            lawsuit_type: data.lawsuit_type,
            overview: data.overview,
            folder: data.folder,
            archived: data.archived,
            updated_by: user?.user.id,
          },
          token: user?.access,
        });

        toast({
          title: t("common.toast.updated_successfully", {
            resource: t("cases.title.case"),
          }),
        });
      } else {
        await civilCasesApi.create({
          url: "/",
          data: {
            name: data.name,
            court: data.court,
            defendant: data.defendant,
            plaintiff: data.plaintiff,
            exact_trial_date: data.exact_trial_date?.toLocaleDateString("sv"),
            inexact_trial_date: data.inexact_trial_date,
            attorney_contact: data.attorney_contact
              ? parseInt(data.attorney_contact)
              : undefined,
            internal_contact: data.internal_contact
              ? parseInt(data.internal_contact)
              : undefined,
            lead_counsel: data.lead_counsel
              ? parseInt(data.lead_counsel)
              : undefined,
            co_counsel: data.co_counsel ? parseInt(data.co_counsel) : undefined,
            opposing_counsel: data.opposing_counsel
              ? parseInt(data.opposing_counsel)
              : undefined,
            lawsuit_type: data.lawsuit_type,
            overview: data.overview,
            folder: data.folder,
            archived: data.archived,
            created_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.added_successfully", {
            resource: t("cases.title.case"),
          }),
        });

        form.reset();
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (reviewCase) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...reviewCase,
            attorney_contact: reviewCase.attorney_contact?.toString() || "",
            internal_contact: reviewCase.internal_contact?.toString() || "",

            lead_counsel:
              reviewCase.subclass_case_object?.lead_counsel?.toString() || "",
            co_counsel:
              reviewCase.subclass_case_object?.co_counsel?.toString() || "",
            opposing_counsel:
              reviewCase.subclass_case_object?.opposing_counsel?.toString() ||
              "",
            lawsuit_type: reviewCase.subclass_case_object?.lawsuit_type || "",

            exact_trial_date: reviewCase.exact_trial_date
              ? new Date(renderDateString(reviewCase.exact_trial_date, true))
              : reviewCase.exact_trial_date,
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCase]);

  const buttonAction = reviewCase?.id
    ? t("common.button.update")
    : t("common.button.add");

  const firstLastAndEmail = (item: AttorneyContact) =>
    `${item.first_name} ${item.last_name} (${item.email})`;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="min-w-[70%] overflow-scroll overflow-x-hidden h-full"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {buttonAction} {t("common.title.case")}
              </DialogTitle>
              {reviewCase?.client && (
                <Collapsible className="rounded-md border px-4 py-3 mt-4 text-sm">
                  <CollapsibleTrigger>
                    <Button type="button" variant="link">
                      {t("cases.button.view_additional_client_info")}
                    </Button>
                  </CollapsibleTrigger>
                  <CollapsibleContent className="border-t-4 py-2">
                    <JsonDisplay data={reviewCase.client} />
                  </CollapsibleContent>
                </Collapsible>
              )}
            </DialogHeader>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.case_name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="court"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.court")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.court")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="defendant"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("cases.label.defendant")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("cases.placeholder.defendant")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="plaintiff"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("cases.label.plaintiff")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("cases.placeholder.plaintiff")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="exact_trial_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>{t("cases.label.exact_trial_date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("cases.statictext.enter_exact_trial_date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("exact_trial_date", null);
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="inexact_trial_date"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("cases.label.inexact_trial_date")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        "cases.placeholder.enter_inexact_trial_date",
                      )}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormLabel className="text-slate-400">
                    {t("cases.statictext.inexact_trial_date_examples")}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="attorney_contact"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("cases.label.attorney_contact")}{" "}
                    {attorneyContactLoading
                      ? t("common.button.loading_with_parens")
                      : ""}
                  </FormLabel>
                  <DynamicSelectorV2
                    onValueChange={field.onChange}
                    value={field.value || undefined}
                    options={attorneyContacts.map((item) => ({
                      label: firstLastAndEmail(item),
                      value: item.id.toString(),
                    }))}
                    className="selector"
                    onLoadNext={loadNext}
                    onSearch={(searchValue) => {
                      search.set(searchValue);
                    }}
                    placeholder={t("common.placeholder.select")}
                    totalCount={attorneyContactsResult?.count || 0}
                    pageSize={contactPagination.pageSize}
                    currentPage={contactPagination.page}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="internal_contact"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("cases.label.internal_contact")}{" "}
                    {usersLoading ? t("common.button.loading_with_parens") : ""}
                  </FormLabel>
                  <DynamicSelectorV2
                    onValueChange={field.onChange}
                    value={field.value || undefined}
                    options={usersList.map((item) => ({
                      // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
                      label: `${item.username} (${item.email})`,
                      value: item.id.toString(),
                    }))}
                    className="selector"
                    onLoadNext={loadNextUsers}
                    onSearch={(searchValue) => {
                      searchUsers.set(searchValue);
                    }}
                    placeholder={t("common.placeholder.select")}
                    totalCount={usersResult?.count || 0}
                    pageSize={pagination.pageSize}
                    currentPage={pagination.page}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="overview"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("cases.label.overview")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("cases.placeholder.overview")}
                      className="resize-none"
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <>
              <FormField
                control={form.control}
                name="lead_counsel"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("cases.label.lead_counsel")}{" "}
                      {attorneyContactLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <DynamicSelectorV2
                      onValueChange={field.onChange}
                      value={field.value || undefined}
                      options={attorneyContacts.map((item) => ({
                        label: firstLastAndEmail(item),
                        value: item.id.toString(),
                      }))}
                      className="selector"
                      onLoadNext={loadNext}
                      onSearch={(searchValue) => {
                        search.set(searchValue);
                      }}
                      placeholder={t("common.placeholder.select")}
                      totalCount={attorneyContactsResult?.count || 0}
                      pageSize={contactPagination.pageSize}
                      currentPage={contactPagination.page}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="co_counsel"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("cases.label.co_counsel")}{" "}
                      {attorneyContactLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <DynamicSelectorV2
                      onValueChange={field.onChange}
                      value={field.value || undefined}
                      options={attorneyContacts.map((item) => ({
                        label: firstLastAndEmail(item),
                        value: item.id.toString(),
                      }))}
                      className="selector"
                      onLoadNext={loadNext}
                      onSearch={(searchValue) => {
                        search.set(searchValue);
                      }}
                      placeholder={t("common.placeholder.select")}
                      totalCount={attorneyContactsResult?.count || 0}
                      pageSize={contactPagination.pageSize}
                      currentPage={contactPagination.page}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="opposing_counsel"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("cases.label.opposing_counsel")}{" "}
                      {attorneyContactLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <DynamicSelectorV2
                      onValueChange={field.onChange}
                      value={field.value || undefined}
                      options={attorneyContacts.map((item) => ({
                        label: firstLastAndEmail(item),
                        value: item.id.toString(),
                      }))}
                      className="selector"
                      onLoadNext={loadNext}
                      onSearch={(searchValue) => {
                        search.set(searchValue);
                      }}
                      placeholder={t("common.placeholder.select")}
                      totalCount={attorneyContactsResult?.count || 0}
                      pageSize={contactPagination.pageSize}
                      currentPage={contactPagination.page}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lawsuit_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("cases.label.lawsuit_type")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("cases.placeholder.lawsuit_type")}
                        {...field}
                        value={field.value || undefined}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
            <FormField
              control={form.control}
              name="folder"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.folder")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.folder")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="archived"
              render={({ field }) => (
                <div>
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("cases.button.archive_case")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
