import React, { useContext } from "react";
import Layout from "@/components/custom/Layout";
import IndexPage from "@/components/custom/IndexPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "@/components/custom/Auth/Login";
import ForgotPassword from "@/components/custom/Auth/ForgotPassword";
import ProtectedRoute from "@/components/custom/Auth/ProtectedRoute";
import Home from "@/components/custom/Home/Home";
import ArchivedCasesTable from "@/components/custom/Cases/ArchivedCasesTable";
import AttorneyContactTable from "@/components/custom/Contacts/AttorneyContactTable";
import UsersTable from "@/components/custom/Users/UsersTable";
import TimelinesTable from "@/components/custom/Timelines/TimelinesTable";
import ResearchFolders from "@/components/custom/Research/Folders/ResearchFolders";
import CaseSourceTable from "@/components/custom/Sources/CaseSourceTable";
import DeadlineTable from "@/components/custom/Deadlines/DeadlineTable";
import SearchPage from "@/components/custom/Search";
import ManageCase from "@/components/custom/Cases/Manage";
import SourceDetails from "@/components/custom/Sources/SourceDetails";
import PrintTimeline from "@/components/custom/Timelines/PrintTimeline";
import Setup2FA from "@/components/custom/Setup2FA";
import { useAdminPermission, usePermission } from "@/hooks/usePermission";
import PageNotFound from "@/components/custom/PageNotFound";
import CaseTimerTable from "@/components/custom/CaseTimer/CaseTimerTable";
import TimeTrackerWidget from "@/components/custom/CaseTimer/TimeTrackerWidget";
import { AuthContext, AuthContextType } from "./context/AuthContext";
import UserIdle from "./components/custom/UserIdle";
import { EULAModal } from "./components/custom/EULAModal";

function App() {
  const hasAdminPermission = useAdminPermission();
  const hasResearchPermission = usePermission("research.add_research");
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route index element={<IndexPage />} />
          <Route path="login" element={<Login />} />
          <Route path="accounts/login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route
            path="Home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="archived-cases"
            element={
              <ProtectedRoute>
                <ArchivedCasesTable />
              </ProtectedRoute>
            }
          />
          {!!hasAdminPermission && (
            <>
              <Route
                path="attorney-contacts"
                element={
                  <ProtectedRoute>
                    <AttorneyContactTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="users"
                element={
                  <ProtectedRoute>
                    <UsersTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="deadlines"
                element={
                  <ProtectedRoute>
                    <DeadlineTable />
                  </ProtectedRoute>
                }
              />
            </>
          )}

          {!!hasResearchPermission && (
            <Route
              path="research-folders"
              element={
                <ProtectedRoute>
                  <ResearchFolders />
                </ProtectedRoute>
              }
            />
          )}

          <Route
            path="timer"
            element={
              <ProtectedRoute>
                <CaseTimerTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="sources"
            element={
              <ProtectedRoute>
                <CaseSourceTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="sources/:id"
            element={
              <ProtectedRoute>
                <SourceDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="timelines"
            element={
              <ProtectedRoute>
                <TimelinesTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="search"
            element={
              <ProtectedRoute>
                <SearchPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="cases/:id"
            element={
              <ProtectedRoute>
                <ManageCase />
              </ProtectedRoute>
            }
          />
          <Route
            path="timelines/case/:id"
            element={
              <ProtectedRoute>
                <PrintTimeline />
              </ProtectedRoute>
            }
          />
          <Route
            path="setup-2fa"
            element={
              <ProtectedRoute>
                <Setup2FA />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Layout>
      {!!user?.user.id && <TimeTrackerWidget />}
      {!!user?.user.id && <UserIdle />}
      {!!user?.user.id && <EULAModal />}
    </BrowserRouter>
  );
}

export default App;
