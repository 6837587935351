import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { Case } from "@/api/cases";
import { reviewApi, ReviewCasePermission } from "@/api/review";
import { DataTable } from "@/components/ui/data-table";
import { useCasePermissionsColumn } from "./hooks";
import Loader from "@/components/ui/loader";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useTranslation } from "react-i18next";

export function PermissionManager({
  open,
  setOpen,
  reviewCase,
  trigger,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reviewCase?: Case;
  trigger?: JSX.Element;
}) {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const [loading, setLoading] = useState<boolean>();
  const [selectedAccordionItem, selectAccordionItem] = useState<
    string | undefined
  >("editors");

  const errorHandler = useApiErrorHandler("PermissionManager", {});

  const [casePermissions, setCasePermissions] =
    useState<ReviewCasePermission>();

  async function fetchCasePermissions() {
    try {
      if (reviewCase?.id) {
        setLoading(true);
        const result = await reviewApi.getCasePermissions({
          id: reviewCase.id,
          token: user?.access,
        });
        setCasePermissions(result.data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  const columns = useCasePermissionsColumn(
    casePermissions?.allowed_users || [],
    reviewCase?.id,
    fetchCasePermissions,
  );

  const handleActiveAccordion = useCallback(
    (value: string) => {
      if (selectedAccordionItem === value) {
        return selectAccordionItem(undefined);
      } else {
        return selectAccordionItem(value);
      }
    },
    [selectedAccordionItem],
  );

  useEffect(() => {
    fetchCasePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCase?.id]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="w-full md:min-w-[70%] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className="text-xl md:text-2xl text-left mb-4">
            {reviewCase?.name} {t("cases.title.permission_management")}
          </DialogTitle>
        </DialogHeader>
        {!!loading && (
          <div className="flex items-center justify-center h-[100%] w-full">
            <Loader />
          </div>
        )}
        <Accordion
          type="single"
          collapsible
          value={selectedAccordionItem}
          className="flex flex-col gap-8 overflow-y-auto"
        >
          {casePermissions?.groups.map((item) => {
            return (
              <AccordionItem
                value={item[0]}
                key={item[0]}
                onClick={() => handleActiveAccordion(item[0])}
              >
                <AccordionTrigger className="bg-primary px-4 text-white">
                  {item[0]}
                </AccordionTrigger>
                <AccordionContent>
                  <DataTable columns={columns} data={item[1]} />
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </DialogContent>
    </Dialog>
  );
}
