import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { Textarea } from "@/components/ui/textarea";
import { reviewApi } from "@/api/review";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useListApi } from "@/hooks/useListApi";
import { Case, casesApi } from "@/api/cases";
import { DynamicSelectorV2 } from "../DynamicSelectorV2";
import { useTranslation } from "react-i18next";

export function InfoRequest({
  open,
  setOpen,
  caseId,
  trigger,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseId?: string;
  trigger?: JSX.Element;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { loading, user } = contextValues;
  const { setLoading } = set;

  const {
    loading: casesLoading,
    search: caseSearch,
    loadNext: loadNextCase,
    list: casesList,
    apiResult: casesResult,
    pagination,
  } = useListApi<Case>({
    baseApiObject: casesApi,
    enable: open,
    additionalListIds: [caseId],
  });

  const FormSchema = z.object({
    title: z.string().min(2, {
      message: t("inforequest.statictext.title_too_short"),
    }),
    message: z.string().min(2, {
      message: t("inforequest.statictext.message_too_short"),
    }),
    case: z.string({
      required_error: t("common.statictext.select_case"),
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const errorHandler = useApiErrorHandler("InfoRequest", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      await reviewApi.requestInformation({
        token: user?.access,
        title: data.title,
        caseId: parseInt(data.case, 0),
        message: data.message,
      });
      setOpen(false);
      form.reset();
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
      toast({
        title: t("inforequest.toast.request_submitted"),
      });
    }
  }

  useEffect(() => {
    if (caseId !== undefined && open) {
      form.setValue("case", caseId, {
        shouldTouch: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>{t("inforequest.title.info_request")}</DialogTitle>
              <DialogDescription>
                {t("inforequest.statictext.info_request")}
              </DialogDescription>
            </DialogHeader>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.title")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("inforequest.placeholder.title_prefix")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="case"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("common.label.case")}{" "}
                    {casesLoading ? t("common.button.loading_with_parens") : ""}
                  </FormLabel>
                  <DynamicSelectorV2
                    onValueChange={field.onChange}
                    value={field.value || undefined}
                    options={casesList.map((item) => ({
                      label: item.name,
                      value: item.id.toString(),
                    }))}
                    className="selector"
                    onLoadNext={loadNextCase}
                    onSearch={(searchValue) => {
                      caseSearch.set(searchValue);
                    }}
                    placeholder={t("common.placeholder.case_select")}
                    totalCount={casesResult?.count || 0}
                    pageSize={pagination.pageSize}
                    currentPage={pagination.page}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("inforequest.label.message")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("inforequest.placeholder.message")}
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading
                  ? t("common.button.loading")
                  : t("common.button.request")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
