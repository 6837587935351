import React, { useContext } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import GitInfo from "react-git-info/macro";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useTranslation } from "react-i18next";

function IndexPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gitInfo = GitInfo();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { backendVersion } = contextValues;

  // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
  const caseReviewAlt = "case review";

  return (
    <div className="flex flex-col text-center justify-center w-full items-center">
      <img
        src="/images/home.png"
        alt={caseReviewAlt}
        className="w-full md:w-[50%] h-auto mt-16"
      />
      <Button className="mt-16" onClick={() => navigate("/home")}>
        {t("index.statictext.enter_site")}
      </Button>
      <div className="mt-8 text-xs text-gray-500">
        <p>
          {t("index.statictext.frontend_version")} {gitInfo.commit.hash}
        </p>
        {backendVersion && (
          <p>
            {t("index.statictext.backend_version")} {backendVersion}
          </p>
        )}
      </div>
    </div>
  );
}

export default IndexPage;
