import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { zodKeys } from "@/lib/utils";
import { User, userApi, UserGroup, userGroupApi } from "@/api/users";
import { useListApi } from "@/hooks/useListApi";
import { DynamicSelectorV2 } from "../DynamicSelectorV2";
import { useTranslation } from "react-i18next";

function generatePassword() {
  const length = 16,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  let i = 0;
  for (; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return retVal;
}

export function AddUpdateUser({
  open,
  setOpen,
  userObject,
  trigger,
  refresh,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userObject?: User;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const FormSchema = z.object({
    username: z.string().min(5, {
      message: t("users.statictext.username_required_with_description"),
    }),
    email: z
      .string()
      .min(1, { message: t("common.statictext.field_required") })
      .email(t("common.statictext.field_required")),
    first_name: z.string().min(2, {
      message: t("common.statictext.first_name_too_short"),
    }),
    last_name: z.string().min(2, {
      message: t("common.statictext.last_name_too_short"),
    }),
    group: z.string().min(1, {
      message: t("users.statictext.select_group"),
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const errorHandler = useApiErrorHandler("AddUpdateUser", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  const {
    loading: userGroupLoading,
    list: userGroupsList,
    apiResult: userGroupsResult,
    search,
    loadNext,
    pagination,
  } = useListApi<UserGroup>({ baseApiObject: userGroupApi, enable: open });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      if (userObject && userObject.id) {
        await userApi.update({
          id: userObject.id.toString() + "/",
          data: {
            username: data.username.toLowerCase(),
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            group: data.group,
            updated_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.updated_successfully", {
            resource: t("users.title.user"),
          }),
        });
      } else {
        await userApi.create({
          url: "/",
          data: {
            username: data.username.toLowerCase(),
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            group: data.group,
            created_by: user?.user.id,
            password: generatePassword(),
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.added_successfully", {
            resource: t("users.title.user"),
          }),
        });
        form.reset();
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (userObject) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...userObject,
            group:
              userObject.groups.find((item) => item.id)?.id.toString() || "",
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject]);

  const buttonAction = userObject?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("users.title.user")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.username")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.username")}
                      {...field}
                      disabled={!!userObject?.id}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.email")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.email")}
                      {...field}
                      disabled={!!userObject?.id}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.first_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.first_name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.last_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.last_name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="group"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    * {t("users.label.group")}{" "}
                    {userGroupLoading
                      ? t("common.button.loading_with_parens")
                      : ""}
                  </FormLabel>
                  <DynamicSelectorV2
                    onValueChange={field.onChange}
                    value={field.value || undefined}
                    options={userGroupsList.map((item) => ({
                      label: item.name,
                      value: item.id.toString(),
                    }))}
                    className="selector"
                    onLoadNext={loadNext}
                    onSearch={(searchValue) => {
                      search.set(searchValue);
                    }}
                    placeholder={t("users.placeholder.group")}
                    totalCount={userGroupsResult?.count || 0}
                    pageSize={pagination.pageSize}
                    currentPage={pagination.page}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
