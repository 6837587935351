import React from "react";
import {
  Worker,
  Viewer,
  PluginOnCanvasLayerRender,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useTranslation } from "react-i18next";

const CaseContentPDF = ({
  file,
  pageFrom,
  pageTo,
}: {
  file: string;
  pageFrom?: number;
  pageTo?: number;
}) => {
  const updatedPageFrom = pageFrom !== undefined ? pageFrom - 1 : undefined;
  const updatedPageTo = pageTo !== undefined ? pageTo - 1 : undefined;
  const { t } = useTranslation();

  const isEventPages = (page: number) => {
    if (updatedPageFrom && updatedPageTo) {
      return updatedPageFrom <= page && page <= updatedPageTo;
    }
    return false;
  };

  const customCanvasPlugin = () => {
    const onCanvasLayerRender = (e: PluginOnCanvasLayerRender) => {
      const hasPageDraw =
        updatedPageFrom !== undefined && updatedPageTo !== undefined;
      if (hasPageDraw && isEventPages(e.pageIndex)) {
        const canvasPdf = e.ele;
        const ctx = canvasPdf.getContext("2d");
        if (ctx) {
          ctx.lineWidth = 10;
          ctx.strokeStyle = "#F57120";
          ctx.rect(0, 0, canvasPdf.width, canvasPdf.height);
          ctx.stroke();
        }
      }
    };
    return { onCanvasLayerRender };
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const customCanvasPluginInstance = customCanvasPlugin();

  // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
  const workerUrl = "/js/pdf.worker.min.js";

  return (
    <Worker workerUrl={workerUrl}>
      <Viewer
        renderError={(error) => {
          console.error(error);
          return (
            <>
              <p className="flex justify-center items-center text-red-600 pt-8">
                {t("common.statictext.failed_to_load_document")}
              </p>
              <p className="flex justify-center items-center text-red-600 pt-8">
                {t("common.statictext.please_check_console")}
              </p>
            </>
          );
        }}
        fileUrl={file}
        plugins={[defaultLayoutPluginInstance, customCanvasPluginInstance]}
        initialPage={updatedPageFrom}
      />
    </Worker>
  );
};

export default CaseContentPDF;
