import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import {
  cn,
  convertFileUrlToFileName,
  renderDateString,
  zodKeys,
} from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { useListApi } from "@/hooks/useListApi";
import { DynamicSelector } from "../DynamicSelector";
import { Pleading, pleadingApi } from "@/api/pleading";
import { Case } from "@/api/cases";
import { FaPaperclip } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { CustomToolTip } from "../CustomToolTip";
import { useTranslation } from "react-i18next";

export function AddUpdatePleading({
  open,
  setOpen,
  pleading,
  trigger,
  refresh,
  reviewCase,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pleading?: Pleading;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
  reviewCase: Case;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { loading, user } = contextValues;
  const { setLoading } = set;

  const {
    loading: pleadingLoading,
    search: pleadingSearch,
    loadNext: loadNextPleading,
    list: pleadingList,
  } = useListApi<Pleading>({
    baseApiObject: pleadingApi,
    enable: open,
    defaultParams: { case: reviewCase.id },
    additionalListIds: [pleading?.associated_pleading?.id.toString()],
  });

  const FormSchema = z.object({
    case_id: z.string().min(1, t("common.statictext.field_required")),
    user_id: z.number().min(1, t("common.statictext.field_required")),
    associated_pleading_id: z.string().nullable().optional(),
    title: z.string().min(1, t("common.statictext.field_required")),
    party: z.string().min(1, t("common.statictext.field_required")),
    description: z.string().min(1, t("common.statictext.field_required")),
    type: z.string().min(1, t("common.statictext.field_required")),
    filing_date: z.date().nullable().optional(),
    response_necessary: z.boolean().default(false),
    due_date: z.date().nullable().optional(),
    associated_pleading: z.string().nullable().optional(),
    file: z.instanceof(File).or(z.string()).nullable().optional(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      case_id: reviewCase.id.toString(),
      user_id: user?.user.id,
      associated_pleading_id: null,
      title: "",
      party: "",
      description: "",
      type: "",
      response_necessary: false,
      filing_date: null,
      due_date: null,
      file: null,
      associated_pleading: "",
    },
  });

  const errorHandler = useApiErrorHandler("AddUpdatePleading", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      if (pleading && pleading.id) {
        await pleadingApi
          .update({
            id: pleading.id.toString() + "/",
            headers: {
              ...(data.file && data.file !== "string"
                ? {
                    "content-type": "multipart/form-data",
                  }
                : {}),
            },
            data: {
              case_id: parseInt(data.case_id),
              user_id: user?.user.id,
              title: data.title,
              party: data.party,
              description: data.description,
              type: data.type,
              filing_date: data.filing_date?.toLocaleDateString("sv"),
              due_date: data.due_date
                ? data.due_date.toLocaleDateString("sv")
                : null,
              response_necessary: data.response_necessary,
              updated_by: user?.user.id,
              associated_pleading_id: data.associated_pleading_id
                ? parseInt(data.associated_pleading_id)
                : undefined,
              ...(typeof data.file === "string" ? {} : { file: data.file }),
            },
            token: user?.access,
          })
          .then(() => {
            toast({
              title: t("common.toast.updated_successfully", {
                resource: t("pleadings.title.pleading"),
              }),
            });
          });
      } else {
        await pleadingApi
          .create({
            url: "/",
            headers: {
              "content-type": "multipart/form-data",
            },
            data: {
              case_id: parseInt(data.case_id),
              user_id: user?.user.id,
              title: data.title,
              party: data.party,
              description: data.description,
              type: data.type,
              filing_date: data.filing_date?.toLocaleDateString("sv"),
              due_date: data.due_date?.toLocaleDateString("sv"),
              response_necessary: data.response_necessary,
              updated_by: user?.user.id,
              associated_pleading_id: data.associated_pleading_id
                ? parseInt(data.associated_pleading_id)
                : undefined,
              ...(typeof data.file === "string" ? {} : { file: data.file }),
            },
            token: user?.access,
          })
          .then(() => {
            toast({
              title: t("common.toast.added_successfully", {
                resource: t("pleadings.title.pleading"),
              }),
            });
            form.reset();
          });
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (pleading) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...pleading,
            case_id: reviewCase.id.toString(),
            filing_date: pleading.filing_date
              ? new Date(renderDateString(pleading.filing_date, true))
              : pleading.filing_date,
            due_date: pleading.due_date
              ? new Date(renderDateString(pleading.due_date, true))
              : pleading.due_date,
            associated_pleading_id: pleading.associated_pleading
              ? pleading.associated_pleading.id.toString()
              : undefined,
            associated_pleading: undefined, // pop it out
            case: undefined, // pop it out
            user: undefined, // pop it out
            user_id: user?.user.id,
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pleading, reviewCase, user]);

  useEffect(() => {
    if (reviewCase && open) {
      form.setValue("case_id", reviewCase.id.toString(), { shouldTouch: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCase, open]);

  const buttonAction = pleading?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("pleadings.title.pleading")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.title")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.title")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="party"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("pleadings.label.party")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("pleadings.placeholder.party")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.description")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("common.placeholder.description")}
                      className="resize-none"
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.type")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("pleadings.placeholder.type")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="filing_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>* {t("pleadings.label.filing_date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("pleadings.statictext.filing_date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("filing_date", null);
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => {
                let fileName = null;
                if (typeof field.value === "string") {
                  fileName = convertFileUrlToFileName(field.value);
                }
                return (
                  <FormItem>
                    <FormLabel>{t("common.label.file")}</FormLabel>
                    {fileName && (
                      <div className="flex gap-2 items-center">
                        <FaPaperclip color="skyblue" />
                        <a
                          href={field.value as string}
                          target="_blank"
                          rel="noreferrer"
                          className="underline text-primary break-all"
                        >
                          {fileName}
                        </a>
                        <CustomToolTip
                          trigger={
                            <ImCross
                              className="cursor-pointer"
                              onClick={() => {
                                form.setValue("file", null);
                              }}
                              color="red"
                            />
                          }
                          tip={t("common.statictext.remove_file")}
                        />
                      </div>
                    )}
                    <FormControl>
                      <Input
                        type="file"
                        {...field}
                        value={undefined}
                        onChange={(event) =>
                          form.setValue(
                            "file",
                            event.target.files ? event.target.files[0] : null,
                          )
                        }
                      />
                    </FormControl>
                    <FormDescription>
                      {t("common.statictext.upload_files")}
                      <p className="text-sm text-info">
                        {t("common.statictext.upload_files_detail")}
                      </p>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="associated_pleading_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("pleadings.label.associated_pleading")}{" "}
                    {pleadingLoading
                      ? t("common.button.loading_with_parens")
                      : ""}
                  </FormLabel>
                  <DynamicSelector
                    onValueChange={field.onChange}
                    value={field.value || undefined}
                    options={pleadingList
                      .filter((item) =>
                        pleading?.id ? pleading.id !== item.id : true,
                      )
                      .map((item) => ({
                        label: item.title,
                        value: item.id.toString(),
                      }))}
                    className="selector"
                    onLoadNext={loadNextPleading}
                    onSearch={(searchValue) => {
                      pleadingSearch.set(searchValue);
                    }}
                    placeholder={t("pleadings.placeholder.associated_pleading")}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="response_necessary"
              render={({ field }) => (
                <div>
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("pleadings.label.response_necessary")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                  <FormDescription className="pl-6">
                    {t("pleadings.statictext.response_necessary")}
                  </FormDescription>
                </div>
              )}
            />
            <FormField
              control={form.control}
              name="due_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>{t("pleadings.label.due_date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("pleadings.statictext.due_date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("due_date", null, {
                              shouldTouch: true,
                              shouldValidate: true,
                            });
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col">
              <FormLabel>{t("common.label.case")}</FormLabel>
              <b>{reviewCase.name}</b>
            </div>
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
