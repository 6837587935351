import React from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import FileViewer from "@/components/custom/FileViewer";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

export function PDFFileViewerModal({
  open,
  setOpen,
  title,
  file,
  trigger,
  pageFrom,
  pageTo,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  trigger?: JSX.Element;
  file: string;
  pageFrom: number;
  pageTo: number;
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="min-w-full"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col md:flex-row gap-8 mb-4">
          <div
            className="w-full"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: window.innerHeight / 1.3,
            }}
          >
            <FileViewer
              fileUrl={file as unknown as string}
              pageFrom={pageFrom}
              pageTo={pageTo}
            />
          </div>
        </div>
        <DialogFooter>
          <Button onClick={() => setOpen(false)}>
            {t("components.label.close")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
