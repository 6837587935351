import { BaseApi } from "@/api/index";
import { User } from "./users";
import { Case } from "./cases";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

// Input
// {
//     "user_id": 23,
//     "case_id": 10,
//     "start_time": "2024-05-06T12:51:55Z",
//     "end_time": "2024-05-06T14:52:51Z",
//     "description": "my task 1 update"
// }

// Output
// {
//     "id": 2,
//     "case": Case
//     "user": User
//     "deleted_at": null,
//     "created_date": "2024-05-06T11:39:09.533123Z",
//     "updated_date": "2024-05-06T11:54:07.065566Z",
//     "start_time": "2024-05-06T12:51:55Z",
//     "end_time": "2024-05-06T14:52:51Z",
//     "description": "my task 1 update",
//     "created_by": null,
//     "updated_by": null,
//     "deleted_by": null
// }

export interface CaseTimer {
  id: number;

  description?: string | null;
  start_time: string;
  end_time: string;

  // Read Only fields
  user: User;
  case?: Case;

  // Only for create and update form
  user_id: number;
  case_id: number;

  created_by: number | null;
  updated_by: number | null;
  deleted_by: number | null;
  deleted_at: string | null;
  created_date: string;
  updated_date: string | null;
}

class CaseTimerApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/case_timers" });
  }
}

export const caseTimerApi = new CaseTimerApi<CaseTimer>();
