import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError, useWatch } from "react-hook-form";
import * as z from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { cn, renderDateString, zodKeys } from "@/lib/utils";
import { CaseEvent, caseEventApi } from "@/api/events";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import { MultiSelect } from "@/components/ui/select";
import { Source, sourcesApi } from "@/api/source";
import { useListApi } from "@/hooks/useListApi";
import { DynamicSelectorV2 } from "../DynamicSelectorV2";
import { useReadApi } from "@/hooks/useReadApi";
import Loader from "@/components/ui/loader";
import { useCaseTags } from "@/api/tags";
import { HexColorPicker } from "react-colorful";
import { Case } from "@/api/cases";
import { Textarea } from "@/components/ui/textarea";
import FileViewer from "@/components/custom/FileViewer";
import { convert } from "html-to-text";
import { useTranslation } from "react-i18next";

export function AddUpdateEvents({
  open,
  setOpen,
  caseEvent,
  trigger,
  refresh,
  defaultCase,
  defaultSource,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseEvent?: CaseEvent;
  trigger?: JSX.Element;
  defaultCase: Case;
  defaultSource?: Source;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const FormSchema = z.object({
    title: z.string().min(5, {
      message: t("events.statictext.title_too_short"),
    }),
    author: z.string().optional(),
    date: z.date(),
    description: z.string().optional(),
    starting_page: z.string().nullable().optional(),
    ending_page: z.string().nullable().optional(),
    starting_bates_number: z.string().optional(),
    ending_bates_number: z.string().optional(),
    include_in_timeline: z.boolean().default(false),
    source_id: z.string().min(1, {
      message: t("events.statictext.select_source"),
    }),
    case_tag_ids: z.string().array(),
    background_color: z.string(),
  });

  const RefinedFormSchema = FormSchema.refine(
    (data) => {
      const startPage = data.starting_page || 0;
      const endPage = data.ending_page || 0;
      return +startPage <= +endPage;
    },
    {
      message: t("common.statictext.endpage_must_be_greater_than_startpage"),
      path: ["ending_page"], // specify the field that will be invalid
    },
  ).refine(
    (data) => {
      const startBatesNumber = data.starting_bates_number || 0;
      const endBatesNumber = data.ending_bates_number || 0;
      return startBatesNumber <= endBatesNumber;
    },
    {
      message: t("common.statictext.endbates_must_be_greater_than_startbates"),
      path: ["ending_bates_number"], // specify the field that will be invalid
    },
  );

  const form = useForm<z.infer<typeof RefinedFormSchema>>({
    resolver: zodResolver(RefinedFormSchema),
    defaultValues: {
      title: "",
      author: "",
      date: new Date(),
      description: "",
      starting_page: "",
      ending_page: "",
      starting_bates_number: "",
      ending_bates_number: "",
      include_in_timeline: true,
      source_id: "",
      case_tag_ids: [],
      background_color: "#ffffff",
    },
  });

  const errorHandler = useApiErrorHandler("AddUpdateEvents", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  const {
    loading: sourceLoading,
    search: sourceSearch,
    loadNext: loadNextSource,
    list: sourceList,
    apiResult: apiSourceResult,
    pagination,
  } = useListApi<Source>({
    baseApiObject: sourcesApi,
    defaultParams: { case_id: defaultCase.id },
    enable: open,
    additionalListIds: [caseEvent?.source?.id.toString()],
  });

  const {
    loading: tagsLoading,
    list: tagsList,
    refresh: refreshTagList,
  } = useCaseTags(defaultCase.id);

  const source_id = useWatch({
    name: "source_id",
    control: form.control,
  });

  const { apiResult, loading: loadingSourceFile } = useReadApi(
    sourcesApi,
    source_id,
  );

  const sanitizeHtmlText = (text: string): string => {
    const plainDescription = convert(text, {
      preserveNewlines: false,
      wordwrap: false,
    });
    return plainDescription;
  };

  async function onSubmit(data: z.infer<typeof RefinedFormSchema>) {
    try {
      setLoading?.(true);
      const plainDescription = sanitizeHtmlText(data.description || "");

      if (caseEvent && caseEvent.id) {
        await caseEventApi.update({
          id: caseEvent.id.toString() + "/",
          data: {
            title: data.title,
            author: data.author,
            date: data.date.toLocaleDateString("sv"),
            description: plainDescription,
            starting_page: data.starting_page,
            ending_page: data.ending_page,
            starting_bates_number: data.starting_bates_number,
            ending_bates_number: data.ending_bates_number,
            include_in_timeline: data.include_in_timeline,
            background_color: data.background_color,
            source_id: data.source_id,
            case_tag_ids: data.case_tag_ids.map((item) => parseInt(item)),
            updated_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.updated_successfully", {
            resource: t("events.title.event"),
          }),
        });
      } else {
        await caseEventApi.create({
          url: "",
          data: {
            title: data.title,
            author: data.author,
            date: data.date.toLocaleDateString("sv"),
            description: plainDescription,
            starting_page: data.starting_page,
            ending_page: data.ending_page,
            starting_bates_number: data.starting_bates_number,
            ending_bates_number: data.ending_bates_number,
            include_in_timeline: data.include_in_timeline,
            background_color: data.background_color,
            source_id: data.source_id,
            case_tag_ids: data.case_tag_ids.map((item) => parseInt(item)),
            created_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.added_successfully", {
            resource: t("events.title.event"),
          }),
        });
        form.reset();
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (caseEvent) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...caseEvent,
            date: caseEvent.date
              ? new Date(renderDateString(caseEvent.date, true))
              : caseEvent.date,
            source_id: caseEvent.source?.id.toString(),
            case_tag_ids: caseEvent.case_tags.map((item) => item.toString()),
            starting_page: caseEvent.starting_page?.toString(),
            ending_page: caseEvent.ending_page?.toString(),
            description: sanitizeHtmlText(caseEvent.description || ""),
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseEvent]);

  useEffect(() => {
    if (open) {
      refreshTagList();
      if (defaultSource) {
        form.setValue("source_id", defaultSource.id.toString(), {
          shouldTouch: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSource, open]);

  const buttonAction = caseEvent?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="min-w-[90%] max-h-screen"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-4"
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("events.title.event")}
              </DialogTitle>
            </DialogHeader>
            <div className="flex flex-col md:flex-row gap-8 mb-4">
              <div
                className="w-full md:min-w-[45%] h-[400px] md:h-[650px]"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                }}
              >
                {apiResult?.file ? (
                  <FileViewer fileUrl={apiResult.file as unknown as string} />
                ) : (
                  <p className="w-full h-full border text-center p-8">
                    {t(
                      "events.statictext.no_file_attached_or_source_not_selected",
                    )}
                  </p>
                )}
                {loadingSourceFile && <Loader />}
              </div>
              <div className="flex w-full md:min-w-[45%] flex-col gap-4 h-[400px] md:h-[650px] px-2 overflow-y-auto">
                <FormField
                  control={form.control}
                  name="source_id"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        * {t("common.label.source")}{" "}
                        {sourceLoading
                          ? t("common.button.loading_with_parens")
                          : ""}
                      </FormLabel>
                      <DynamicSelectorV2
                        onValueChange={field.onChange}
                        value={field.value || undefined}
                        options={sourceList.map((item) => ({
                          label: item.title,
                          value: item.id.toString(),
                        }))}
                        className="selector"
                        onLoadNext={loadNextSource}
                        onSearch={(searchValue) => {
                          sourceSearch.set(searchValue);
                        }}
                        placeholder={t("events.placeholder.source")}
                        totalCount={apiSourceResult?.count || 0}
                        pageSize={pagination.pageSize}
                        currentPage={pagination.page}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>* {t("common.label.title")}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("events.placeholder.title")}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="author"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("common.label.author")}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("common.placeholder.author")}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="date"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>{t("common.label.date")}</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-[240px] pl-3 text-left font-normal",
                                !field.value && "text-muted-foreground",
                              )}
                            >
                              {field.value ? (
                                renderDateString(
                                  field.value as unknown as number,
                                )
                              ) : (
                                <span>
                                  {t("common.statictext.pick_a_date")}
                                </span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            captionLayout="dropdown-buttons"
                            fromYear={1960}
                            toYear={2030}
                            onSelect={field.onChange}
                            selected={field.value || undefined}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormDescription>
                        {t("events.statictext.date")}
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("common.label.description")}</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder={t("events.placeholder.description")}
                          className="resize-none"
                          {...field}
                          value={field.value || undefined}
                          rows={10}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="case_tag_ids"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t("events.label.case_tags")}{" "}
                        {tagsLoading
                          ? t("common.button.loading_with_parens")
                          : ""}
                      </FormLabel>
                      <MultiSelect
                        selected={field.value || []}
                        options={tagsList
                          .map((item) => ({
                            label: item.name || item.tag.display_name,
                            value: item.id.toString(),
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        {...field}
                        className="sm:w-[510px]"
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="starting_page"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>* {t("common.label.start_page")}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("common.placeholder.start_page")}
                          {...field}
                          value={field.value || undefined}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ending_page"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("common.label.end_page")}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("common.placeholder.end_page")}
                          {...field}
                          value={field.value || undefined}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="starting_bates_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t("common.label.start_bates_number")}
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t(
                            "common.placeholder.start_bates_number",
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ending_bates_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t("common.label.end_bates_number")}
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("common.placeholder.end_bates_number")}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="background_color"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t("events.label.background_color")}
                      </FormLabel>
                      <FormControl className="!mb-4">
                        <HexColorPicker
                          color={field.value || "#ffffff"}
                          onChange={(value: string) => {
                            form.setValue("background_color", value);
                          }}
                        />
                      </FormControl>
                      <FormLabel>
                        {t("events.label.selected_color_preview")}
                      </FormLabel>
                      <FormDescription className="!mb-4">
                        <div
                          className="rounded h-16 w-16"
                          style={{
                            backgroundColor: field.value,
                          }}
                        />
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="include_in_timeline"
                  render={({ field }) => (
                    <div>
                      <FormItem className="flex flex-row items-start gap-2">
                        <FormControl>
                          <Checkbox
                            checked={field.value || false}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <div className="flex flex-col gap-2 items-start justify-start !mt-0">
                          <FormLabel className="!mt-0">
                            {t("events.label.include_in_timeline")}
                          </FormLabel>
                          <FormDescription>
                            {t(
                              "events.statictext.include_in_timeline_description",
                            )}
                          </FormDescription>
                        </div>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />
                <DialogFooter className="my-8 px-8 flex gap-4">
                  <Button
                    disabled={loading}
                    variant="secondary"
                    onClick={() => setOpen(false)}
                    type="button"
                  >
                    {loading
                      ? t("common.button.loading")
                      : t("common.button.cancel")}
                  </Button>
                  <Button disabled={loading} type="submit">
                    {loading ? t("common.button.loading") : buttonAction}
                  </Button>
                </DialogFooter>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
