import { BaseApi } from "@/api/index";
import { CaseTag } from "./tags";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export interface ClientUploadCase {
  your_name: string;
  your_email: string;
  organization?: string;
  attorney_name: string;
  attorney_email: string;
  client_name: string;
  case_number?: string;
  expedite_request?: string;
  case_type?: string;
  documents?: FileList | null | undefined;
  captcha_token: string;
}

export interface Case {
  id: number;

  created_date: string;
  updated_date?: string | null;
  deleted_date?: string | null;
  created_by: number;
  updated_by?: number | null;
  deleted_by?: number | null;

  // when they upload/create by themselves.
  client?: Object | null;

  name: string;
  court?: string | null;
  defendant?: string | null;
  plaintiff?: string | null;
  exact_trial_date?: string | null;
  inexact_trial_date?: string | null;
  attorney_contact?: number | null;
  internal_contact?: number | null;
  overview?: string | null;
  folder?: string | null;
  archived: boolean | null;

  // readonly
  is_civil: boolean;
  case_number: string;
  default_tags: CaseTag[];
  custom_tags: CaseTag[];
}

export interface CaseCriminal extends Case {
  subclass_case_object?: {
    prosecutor?: string | null;
    current_charges?: string | null;
  } | null;
  prosecutor?: string | null;
  current_charges?: string | null;
}

export interface CaseCivil extends Case {
  subclass_case_object?: {
    lead_counsel?: number | null;
    co_counsel?: number | null;
    opposing_counsel?: number | null;
    lawsuit_type?: string | null;
  } | null;
  lead_counsel?: number | null;
  co_counsel?: number | null;
  opposing_counsel?: number | null;
  lawsuit_type?: string | null;
}

class CasesApi<T = unknown> extends BaseApi<T> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/cases" });
  }
}

class CivilCasesApi extends BaseApi<CaseCivil> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/civil-cases" });
  }
}

class CriminalCasesApi extends BaseApi<CaseCriminal> {
  constructor() {
    super({ apiBaseUrl: baseUrl, base: "api/criminal-cases" });
  }
}

export const casesApi = new CasesApi<CaseCriminal | CaseCivil>();
export const civilCasesApi = new CivilCasesApi();
export const criminalCasesApi = new CriminalCasesApi();
