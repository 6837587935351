import React, { useCallback, useContext } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError, useWatch } from "react-hook-form";
import * as z from "zod";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { cn, convertFileUrlToFileName, renderDateString } from "@/lib/utils";
import { sourcesApi } from "@/api/source";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { FaPaperclip } from "react-icons/fa6";
import { MultiSelect } from "@/components/ui/select";
import { useListApi } from "@/hooks/useListApi";
import { User, userApi } from "@/api/users";
import { Discovery } from "@/api/discovery";
import { useTranslation } from "react-i18next";

export function AddCaseSourceFromDiscovery({
  discovery,
  trigger,
  caseId,
  refresh,
  open,
  setOpen,
}: {
  discovery: Discovery;
  trigger?: JSX.Element;
  caseId: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const { loading: usersLoading, list: usersList } = useListApi<User>({
    baseApiObject: userApi,
    defaultPageSize: 1000,
    enable: open,
  });

  const FormSchema = z.object({
    title: z.string().min(2, {
      message: t("sources.statictext.title_too_short"),
    }),
    institution: z.string().optional(),
    description: z.string().optional(),
    type: z.string().optional(),
    start_date: z.date().nullable().optional(),
    end_date: z.date().nullable().optional(),
    total_pages: z.string().nullable().optional(),
    discovery_file: z.number(),
    requires_text_extraction: z.boolean().optional().default(false),
    extracted_text: z.string().nullable().optional(),
    extracted_text_per_page: z.string().nullable().optional(),
    use_bates_numbers: z.boolean().optional().default(false),
    source_starting_bates_number: z.string().optional(),
    corresponding_page_number: z.string().nullable().optional(),

    // Only required when creating source.
    send_notification_emails: z.boolean().nullable().optional().default(false),
    notification_recipients: z.string().array().nullable().optional(),
  });

  const RefinedFormSchema = FormSchema.refine(
    (data) => {
      if (data.start_date && data.end_date) {
        return (
          data.end_date?.setHours(0, 0, 0, 0) >=
          data.start_date?.setHours(0, 0, 0, 0)
        );
      }
      return true;
    },
    {
      message: t("sources.statictext.start_date_must_be_earlier"),
      path: ["start_date"],
    },
  );

  const form = useForm<z.infer<typeof RefinedFormSchema>>({
    resolver: zodResolver(RefinedFormSchema),
    defaultValues: {
      title: "",
      institution: "",
      description: "",
      type: "",
      start_date: null,
      end_date: null,
      total_pages: "",
      discovery_file: discovery.id,
      requires_text_extraction: false,
      extracted_text: "",
      extracted_text_per_page: "",
      use_bates_numbers: false,
      source_starting_bates_number: "",
      corresponding_page_number: "",
    },
  });

  const errorHandler = useApiErrorHandler("AddCaseSourceFromDiscovery", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  const send_notification_emails = useWatch({
    name: "send_notification_emails",
    control: form.control,
  });

  async function onSubmit(data: z.infer<typeof RefinedFormSchema>) {
    try {
      setLoading?.(true);

      await sourcesApi.create({
        url: "/",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: {
          title: data.title,
          case: caseId,
          institution: data.institution,
          description: data.description,
          type: data.type,
          total_pages: data.total_pages,
          requires_text_extraction: data.requires_text_extraction,
          extracted_text: data.extracted_text,
          extracted_text_per_page: data.extracted_text_per_page,
          use_bates_numbers: data.use_bates_numbers,
          source_starting_bates_number: data.source_starting_bates_number,
          corresponding_page_number: data.corresponding_page_number,
          discovery_file: discovery.id,
          start_date: data.start_date?.toLocaleDateString("sv"),
          end_date: data.end_date?.toLocaleDateString("sv"),
          send_notification_emails: data.send_notification_emails
            ? "on"
            : "off",
          notification_recipients:
            data.notification_recipients?.join(",") || "",
          created_by: user?.user.id,
        },
        token: user?.access,
      });
      toast({
        title: t("common.toast.added_successfully", {
          resource: t("discovery.title.discovery"),
        }),
      });
      await refresh?.();
      setOpen(false);
      form.reset();
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  const buttonAction = t("common.button.add");

  const renderFile = useCallback(() => {
    const fileName = convertFileUrlToFileName(discovery.file);

    return (
      <div className="flex gap-2 items-center">
        <FaPaperclip color="skyblue" />
        <a
          href={discovery.file as string}
          target="_blank"
          rel="noreferrer"
          className="underline text-primary break-all"
        >
          {fileName}
        </a>
      </div>
    );
  }, [discovery]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="min-w-[70%] overflow-scroll overflow-x-hidden h-full"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("sources.title.source")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.title")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.title")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="institution"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("sources.label.institution")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("sources.placeholder.institution")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.description")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("common.placeholder.description")}
                      className="resize-none"
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.type")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("sources.placeholder.type")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="start_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>{t("sources.label.start_date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("sources.statictext.start_date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("start_date", null);
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="end_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>{t("sources.label.end_date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>

                  <FormDescription>
                    <div className="flex flex-wrap gap-2 items-center justify-start">
                      <p>{t("sources.statictext.end_date")}</p>
                      {field.value && (
                        <Button
                          type="button"
                          className="w-auto"
                          variant="link"
                          onClick={() => {
                            form.setValue("end_date", null);
                          }}
                        >
                          {t("common.button.clear")}
                        </Button>
                      )}
                    </div>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="total_pages"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("sources.label.total_pages")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("sources.placeholder.total_pages")}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {renderFile()}
            <FormField
              control={form.control}
              name="use_bates_numbers"
              render={({ field }) => (
                <div className="flex flex-col gap-1">
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("sources.label.use_bates_numbers")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                  <FormDescription>
                    {t("sources.statictext.use_bates_numbers")}
                  </FormDescription>
                </div>
              )}
            />

            <FormField
              control={form.control}
              name="send_notification_emails"
              render={({ field }) => (
                <div>
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("sources.label.send_notification_emails")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />

            {send_notification_emails && (
              <FormField
                control={form.control}
                name="notification_recipients"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("sources.label.notification_recipients")}{" "}
                      {usersLoading
                        ? t("common.button.loading_with_parens")
                        : ""}
                    </FormLabel>
                    <MultiSelect
                      selected={field.value || []}
                      options={usersList
                        .filter((item) => item.email)
                        .map((item) => ({
                          // eslint-disable-next-line @bigbinary/neeto/hard-coded-strings-should-be-localized
                          label: `${item.first_name} ${item.last_name} (${item.email})`,
                          value: item.id.toString(),
                        }))}
                      {...field}
                      className="sm:w-[510px]"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="source_starting_bates_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("sources.label.starting_bates_number")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        "sources.placeholder.starting_bates_number",
                      )}
                      {...field}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="corresponding_page_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("sources.label.corresponding_page_number")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        "sources.placeholder.corresponding_page_number",
                      )}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
