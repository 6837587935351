import React, { useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { Pleading, pleadingApi } from "@/api/pleading";
import { useTranslation } from "react-i18next";

export function DeletePleading({
  trigger,
  pleading,
  refresh,
}: {
  trigger: JSX.Element;
  pleading: Pleading;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const errorHandler = useApiErrorHandler("DeletePleading", {});

  async function onSubmit() {
    try {
      setLoading?.(true);
      await pleadingApi.delete({
        id: pleading.id.toString(),
        token: user?.access,
        data: {
          deleted_by: user?.user.id,
        },
      });
      toast({
        title: t("common.toast.deleted_successfully", {
          resource: t("pleadings.title.pleading"),
        }),
      });
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  function onCancel() {
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{t("pleadings.title.delete")}</DialogTitle>
          <DialogDescription>
            {t("common.statictext.confirm_delete", { name: pleading.title })}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button variant="secondary" disabled={loading} onClick={onCancel}>
            {loading ? t("common.button.loading") : t("common.button.cancel")}
          </Button>
          <Button variant="destructive" disabled={loading} onClick={onSubmit}>
            {loading ? t("common.button.loading") : t("common.button.confirm")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
