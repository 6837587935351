import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { useToast } from "../ui/use-toast";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { caseReviewAuthApi } from "@/api/auth";
import { useTranslation } from "react-i18next";

export function EULAModal() {
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { t } = useTranslation();

  const { user, loading } = contextValues;
  const errorHandler = useApiErrorHandler("EULAModal", {});

  const checkEULA = async () => {
    try {
      const result = await caseReviewAuthApi.fetchEULA({
        token: user?.access,
      });
      if (result.data.accepted_eula === true) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const acceptEULA = async () => {
    try {
      const result = await caseReviewAuthApi.acceptEULA({
        token: user?.access,
      });
      if (result.status === 200) {
        await checkEULA();
      } else {
        toast({
          title: t("common.statictext.eula_failed"),
          variant: "destructive",
        });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!!user?.user.id && !loading) {
      checkEULA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.user.id, loading]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto [&>button.x-close-button]:hidden"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onKeyDown={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{t("common.statictext.eula")}</DialogTitle>
        </DialogHeader>
        <p className="mt-4 mb-4">
          {t("common.statictext.eula_accept_text")}{" "}
          <a
            className="text-primary underline"
            href="/eula.html"
            target="_blank"
            referrerPolicy="no-referrer"
          >
            {t("common.statictext.eula")}
          </a>
          {"."}
        </p>
        <DialogFooter>
          <Button type="button" variant="default" onClick={acceptEULA}>
            {t("common.button.accept")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
