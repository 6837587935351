import React, { useContext, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { cn, renderDateString, zodKeys } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { Case } from "@/api/cases";
import { Communication, communicationsApi } from "@/api/communications";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTranslation } from "react-i18next";

export function AddUpdateCommunication({
  open,
  setOpen,
  communication,
  trigger,
  refresh,
  reviewCase,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  communication?: Communication;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
  reviewCase: Case;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { loading, user } = contextValues;
  const { setLoading } = set;

  const FormSchema = z.object({
    // case_id: z.string().min(1, "case id is required"),
    user_id: z.number().min(1, t("common.statictext.field_required")),
    person: z.string().min(1, t("common.statictext.field_required")),
    communication_type: z
      .string()
      .min(1, t("common.statictext.field_required")),
    start_date: z.date(),
    duration_unit: z.string().min(1, t("common.statictext.field_required")),
    duration_amount: z.string().default("0"),
    public: z.boolean().default(false),
    notes: z.string().nullable().optional(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      // case_id: reviewCase.id.toString(),
      user_id: user?.user.id,
      person: "",
      communication_type: "",
      start_date: new Date(),
      duration_amount: "0",
      duration_unit: "",
      public: false,
      notes: "",
    },
  });

  const errorHandler = useApiErrorHandler("AddUpdateCommunication", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      if (communication && communication.id) {
        await communicationsApi
          .update({
            id: communication.id.toString() + "/",
            data: {
              case_id: reviewCase.id,
              user_id: user?.user.id,
              person: data.person,
              communication_type: data.communication_type,
              start_date: data.start_date.toLocaleDateString("sv"),
              duration_amount: parseInt(data.duration_amount),
              duration_unit: data.duration_unit,
              public: data.public,
              notes: data.notes,
            },
            token: user?.access,
          })
          .then(() => {
            toast({
              title: t("common.toast.updated_successfully", {
                resource: t("communications.title.communication_suffix"),
              }),
            });
          });
      } else {
        await communicationsApi
          .create({
            url: "/",
            data: {
              case_id: reviewCase.id,
              user_id: user?.user.id,
              person: data.person,
              communication_type: data.communication_type,
              start_date: data.start_date.toLocaleDateString("sv"),
              duration_amount: parseInt(data.duration_amount),
              duration_unit: data.duration_unit,
              public: data.public,
              notes: data.notes,
            },
            token: user?.access,
          })
          .then(() => {
            toast({
              title: t("common.toast.added_successfully", {
                resource: t("communications.title.communication_suffix"),
              }),
            });
            form.reset();
          });
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (communication) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          {
            ...communication,
            start_date: communication.start_date
              ? new Date(renderDateString(communication.start_date, true))
              : communication.start_date,
            case: undefined, // pop it out
            user: undefined, // pop it out
            user_id: user?.user.id,
            duration_amount: communication.duration_amount.toString(),
          }[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication, reviewCase, user]);

  const buttonAction = communication?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("communications.title.communication_suffix")}
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="person"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("communications.label.person")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("communications.placeholder.person")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="start_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>{t("common.label.date")}</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            renderDateString(field.value as unknown as number)
                          ) : (
                            <span>{t("common.statictext.pick_a_date")}</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        fromYear={1960}
                        toYear={2030}
                        onSelect={field.onChange}
                        selected={field.value || undefined}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    {t("communications.statictext.date")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="duration_amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("communications.label.duration_amount")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        "communications.placeholder.duration_amount",
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="duration_unit"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    * {t("communications.label.duration_unit")}
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t("common.placeholder.select")}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="hour">
                        {t("communications.values.hour")}
                      </SelectItem>
                      <SelectItem value="minute">
                        {t("communications.values.minute")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    {t("communications.statictext.duration_unit")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="communication_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.type")}</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t("common.placeholder.select")}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="phone_call">
                        {t("communications.values.phone_call")}
                      </SelectItem>
                      <SelectItem value="voicemail">
                        {t("communications.values.voicemail")}
                      </SelectItem>
                      <SelectItem value="email">
                        {t("communications.values.email")}
                      </SelectItem>
                      <SelectItem value="other">
                        {t("communications.values.other")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    {t("communications.statictext.type")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.notes")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("common.placeholder.notes")}
                      className="resize-none"
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="public"
              render={({ field }) => (
                <div>
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="!mt-0">
                      {t("communications.label.public")}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
